import { useEffect, useState } from "react"

const useCurrentLocation = location => {
  const [isHomepage, setIsHomepage] = useState(false)
  const pathname = location ? location.pathname : ""

  useEffect(() => {
    if (pathname === "/") {
      setIsHomepage(true)
    } else {
      setIsHomepage(false)
    }
  }, [pathname])

  return isHomepage
}

export default useCurrentLocation
