import { useEffect, useState } from "react"

const useHeaderNavigation = () => {
  const [showNav, setShowNav] = useState(false)

  useEffect(() => {
    const main = document.querySelector("main")
    const footer = document.querySelector("footer")
    const navigation = document.getElementById("navigation")

    const clickOutside = e =>
      !e.target.closest("header") ? setShowNav(false) : null

    document.addEventListener("click", clickOutside)

    if (showNav) {
      main.classList.add("blur-sm")
      footer.classList.add("hidden")
      navigation?.classList.add("!hidden")
    } else {
      main.classList.remove("blur-sm")
      footer.classList.remove("hidden")
      navigation?.classList.remove("!hidden")
    }

    return () => {
      document.removeEventListener("click", clickOutside)
    }
  }, [showNav])

  return { showNav, setShowNav }
}

export default useHeaderNavigation
