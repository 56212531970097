import AniLink from "gatsby-plugin-transition-link/AniLink"
import React, { useCallback, useMemo, useState, useEffect } from "react"
import { ROUTES } from "./Layout"

const Navigation = ({ location }) => {
  const pathname = location.pathname
  const [isGoNextDisabled, setIsGoNextDisabled] = useState(false)
  const [isGoBackDisabled, setIsGoBackDisabled] = useState(false)

  const routeIndex = useMemo(
    () => ROUTES.findIndex(route => route.path === pathname),
    [pathname]
  )

  useEffect(() => {
    if (routeIndex === 0) {
      setIsGoBackDisabled(true)
    } else {
      setIsGoBackDisabled(false)
    }

    if (routeIndex === ROUTES.length - 1) {
      setIsGoNextDisabled(true)
    } else {
      setIsGoNextDisabled(false)
    }
  }, [routeIndex])

  const setPreviousLink = useCallback(
    () => ROUTES[Math.max(0, routeIndex - 1)].path,
    [routeIndex]
  )
  const setNextLink = useCallback(
    () => ROUTES[Math.min(routeIndex + 1, ROUTES.length - 1)].path,
    [routeIndex]
  )

  return (
    <nav
      id="navigation"
      className="px-4 lg:p-4 my-10 xl:my-0 flex justify-between xl:fixed xl:inset-0 xl:top-[calc(100vh-10rem)] max-w-[1920px] mx-auto xl:z-[1]"
    >
      <AniLink
        aria-label="Vorherige Seite"
        swipe
        direction="right"
        to={setPreviousLink()}
        className={`${
          isGoBackDisabled
            ? "cursor-not-allowed !text-gray-100 [&>svg]:!stroke-gray-100"
            : ""
        } group text-black text-center rounded h-fit`}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="size-[76px] stroke-red-200 group-hover:stroke-black text-lg"
        >
          <rect width="18" height="18" x="3" y="3" rx="2" />
          <path d="m12 8-4 4 4 4" />
          <path d="M16 12H8" />
        </svg>
        Vorherige
      </AniLink>
      <AniLink
        aria-label={
          isGoNextDisabled ? "Keine weiteren Seiten" : "Nächste Seite"
        }
        swipe
        direction="left"
        to={setNextLink()}
        className={`${
          isGoNextDisabled
            ? "cursor-not-allowed !text-gray-100 [&>svg]:!stroke-gray-100"
            : ""
        } group text-black  text-center rounded h-fit`}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="size-[76px] stroke-red-200 group-hover:stroke-black rotate-180 text-lg"
        >
          <rect width="18" height="18" x="3" y="3" rx="2" />
          <path d="m12 8-4 4 4 4" />
          <path d="M16 12H8" />
        </svg>
        Nächste
      </AniLink>
    </nav>
  )
}

export default Navigation
