import React from "react"
import { EXTERNAL_LINKS } from "./Layout"
import useCurrentLocation from "../hooks/useCurrentLocation"

const Footer = ({ location }) => {
  const isHomePage = useCurrentLocation(location)

  return (
    <footer className="xl:relative xl:z-[2] flex flex-col-reverse lg:flex-row justify-between gap-4 lg:gap-8 p-4 px-4 lg:py-0 xl:px-24 lg:h-14 max-w-8xl mx-auto">
      <div className="flex flex-col-reverse lg:flex-row gap-4 justify-between items-center">
        <p className="text-black text-base text-center mb-0">
          © 2024 Duale Hochschule{" "}
          <span className="sm:whitespace-nowrap">Baden-Württemberg</span>
        </p>
      </div>
      <div className="grid grid-cols-2 sm:flex justify-center lg:justify-end gap-4">
        {EXTERNAL_LINKS.map(link => (
          <a
            key={link.path}
            className={`footer-link ${isHomePage ? "border-none" : ""}`}
            href={link.path}
          >
            {link.name}
          </a>
        ))}
      </div>
    </footer>
  )
}

export default Footer
