exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-das-jahr-an-der-dhbw-jsx": () => import("./../../../src/pages/das-jahr-an-der-dhbw.jsx" /* webpackChunkName: "component---src-pages-das-jahr-an-der-dhbw-jsx" */),
  "component---src-pages-das-praesidium-der-dhbw-jsx": () => import("./../../../src/pages/das-praesidium-der-dhbw.jsx" /* webpackChunkName: "component---src-pages-das-praesidium-der-dhbw-jsx" */),
  "component---src-pages-die-dhbw-auf-einen-blick-jsx": () => import("./../../../src/pages/die-dhbw-auf-einen-blick.jsx" /* webpackChunkName: "component---src-pages-die-dhbw-auf-einen-blick-jsx" */),
  "component---src-pages-finanzen-und-haushalt-jsx": () => import("./../../../src/pages/finanzen-und-haushalt.jsx" /* webpackChunkName: "component---src-pages-finanzen-und-haushalt-jsx" */),
  "component---src-pages-grussworte-der-praesidentin-jsx": () => import("./../../../src/pages/grussworte-der-praesidentin.jsx" /* webpackChunkName: "component---src-pages-grussworte-der-praesidentin-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mitarbeitende-an-der-dhbw-jsx": () => import("./../../../src/pages/mitarbeitende-an-der-dhbw.jsx" /* webpackChunkName: "component---src-pages-mitarbeitende-an-der-dhbw-jsx" */),
  "component---src-pages-studierende-an-der-dhbw-jsx": () => import("./../../../src/pages/studierende-an-der-dhbw.jsx" /* webpackChunkName: "component---src-pages-studierende-an-der-dhbw-jsx" */),
  "component---src-pages-using-ssr-jsx": () => import("./../../../src/pages/using-ssr.jsx" /* webpackChunkName: "component---src-pages-using-ssr-jsx" */)
}

